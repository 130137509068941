import React from "react";
import NavigationBar from "./Components/mainNavigationBar";
import { Layout } from "antd";
import { TextMarquee } from "./Components/TextMarquee";
import { CompanyLogo } from "./Components/companyLogo";
import AppFooter from "./Components/Footer";
import HomeEffect from "./Components/HomeEffect";
const { Header, Content, Footer } = Layout;

export function Home() {
  return (
    <>
      {/* background effect */}
      <HomeEffect />
      <Layout className="flex flex-col font-futura min-h-screen bg-[radial-gradient(ellipse_at_bottom,_var(--tw-gradient-stops))] from-[#05081c] via-[#05081c] to-[#090E32]">
        <Header className="flex w-full p-0 ">
          <NavigationBar />
        </Header>
        <Content className="z-20 flex flex-col  justify-between">
          <div className=""></div>
          {/* added stylized box */}
          <div className="flex flex-col border-2 border-[#ffffff05] self-center bg-gray-300/5 w-fit rounded-none sm:rounded-none md:rounded-3xl lg:rounded-3xl xl:rounded-3xl 2xl:rounded-3xl backdrop-blur-sm p-5 my-10 px-7 shadow-3xl ">
            <div className="hidden sm:hidden md:flex lg:flex xl:flex 2xl:flex flex-row justify-center items-center ">
              <div>
                <CompanyLogo width="250" />
              </div>
            </div>
            <div className="flex flex-row justify-center items-center mt-10">
              <p className="capitalize font-proxima-nova font-thin text-white text-4xl text-center max-w-3xl px-4">
                A Healthcare Technology Firm Builing A Computational and
                Data-Driven Ecosystem For Precision Medicine
              </p>
            </div>
          </div>
          <div className="flex flex-col w-full bg-[#05081c]">
            <TextMarquee
              velocity="15"
              direction="ltr"
              text="CCATTTCTTCCTATTACAATGCCAACAGCAAATCTTGGAATTGGGACATCTCGCGCCAAATGAGACCGTAGAATCGATGGGAATCTGTTGAGGCTTGTGAGG"
            />
            <TextMarquee
              velocity="15"
              direction="rtl"
              text="CTGAAACTTCATCTCACCATGAGGCTCCACAGGATCCACAATCATTGTGAGCGGGTCTCGGCAGGCCTCCTCCGAAGGCTGGGCGGGGCACACTCCTGCCGG"
            />
          </div>
        </Content>
        <Footer className="p-0 z-10">
          <AppFooter />
        </Footer>
      </Layout>
    </>
  );
}
