import React from "react";
import { useParams } from "react-router-dom";
import { Layout } from "antd";
import NavigationBar from "./mainNavigationBar";
import AppFooter from "./Footer";

const { Header, Content, Footer } = Layout;

const jobPostings = [
  {
    id: "computational-biologist",
    jobTitle: "Computational Biologist",
    linkedinpostinglink: "https://www.linkedin.com/jobs/view/4083817567/",
    about:
      "Flahy is a healthcare technology firm that develops artificial intelligence driven precision medicine products and collaborates with institutions for the development of targeted therapies. Flahy has developed FlahyBase, a digital biology platform that leverages sophisticated statistical & computational capabilities to analyze -omics and medical imaging data across individuals and populations for prognosis, personalizing treatment, and target discovery. FlahyRecovery™ is Flahy’s first precision medicine product built on the FlahyBase platform.",
    positionDescription: "Work on precision diagnostic product line...",
    positionResponsibilities: [
      "Conducting product focused Research and Development. This would involve analysing clinical and research literature, existing products, and computing over -omics datasets to identify patterns that are of clinical relevance for patient sub-typing, and subsequent treatment/therapeutic guidance.",
      "Maintain and continue to develop in-house developed -omics data analysis algorithms and software tools/pipelines.",
      "Exploration and deployment of external algorithms, software, and tools for omics data analysis.",
      "Work with team members at Flahy to develop the computational platform and a proprietary database of sequenced and imaged tissue data.",
      "Multi-task in a fast-paced environment.",
    ],
    minimumRequirements: [
      "An MSc. or a Ph.D. in biological sciences, computational biology, mathematics, statistics, computer science or related quantitative field. Or a B.Tech/B.Eng/B.Sc from a credible institution.",
      "Strong research & development experience in either an academic or an industrial setting.",
      "Credible publications are a plus.",
    ],
    contactEmail: "contact@flahybase.com",
    emailSubject: "CBFHY",
  },
  {
    id: "data-scientist",
    jobTitle: "Data Scientist: ML Engineer",
    linkedinpostinglink: "https://www.linkedin.com/jobs/view/4102189254/",
    about:
      "Flahy is a healthcare technology firm that develops artificial intelligence driven precision medicine products and collaborates with institutions for the development of targeted therapies. Flahy has developed FlahyBase, a digital biology platform that leverages sophisticated statistical & computational capabilities to analyze -omics and medical imaging data across individuals and populations for prognosis, personalizing treatment, and target discovery. FlahyRecovery™ is Flahy’s first precision medicine product built on the FlahyBase platform.",
    positionDescription:
      "Flahy is seeking a Data Scientist / Machine Learning engineer with a strong background in theocratical mathematics & statistics to collaborate with the team on development of its computational platform and proprietary scientific computing libraries.",
    positionResponsibilities: [
      "Conducting product focused Research and Development. This would involve designing mathematical/statistical models for analysis of large graphical, high-dimensional, and multi-modal data.",
      "Design and train machine learning models for feature learning, segmentation, and classification from medical imaging data.",
      "Maintain and continue to develop in-house developed -omics data analysis software.",
      "Exploration and deployment of external algorithms, software, and tools for -omics data analysis.",
      "Work with team members at Flahy to develop the computational platform and a proprietary database of sequenced and imaged tissue data.",
      "Multi-task in a fast-paced environment.",
    ],
    minimumRequirements: [
      "An MSc. or a Ph.D. in mathematics, statistics, physics, computer science, biomedical engineering, or related quantitative field. Or a B.Tech/B.Eng/B.Sc from a credible institution.",
      "Research experience in mathematics, theoretical physics, or machine/statistical learning.",
      "Credible publications or preprints are a plus.",
    ],
    contactEmail: "contact@flahybase.com",
    emailSubject: "MLFHY",
  },
  {
    id: "Software Engineer (Full-Stack)",
    jobTitle: "Software Engineer (Full-Stack)",
    about:
      "Flahy is a healthcare technology firm that develops artificial intelligence driven precision medicine products and collaborates with institutions for the development of targeted therapies. Flahy has developed FlahyBase, a digital biology platform that leverages sophisticated statistical & computational capabilities to analyze -omics and medical imaging data across individuals and populations for prognosis, personalizing treatment, and target discovery. FlahyRecovery™ is Flahy’s first precision medicine product built on the FlahyBase platform.",
    positionDescription:
      "We're looking for a Software Engineer (Full-stack) with a passion for building software for large biomedical data analysis and development/management of multi-interface applications for our precision medicine product line. In this role, you will have the opportunity to work on complex problems, and  interface closely with the R&D Team to build proprietary software toolkits, scientific computing packages, and scalable computational pipelines.",
    positionResponsibilities: [
      "Full-Stack Development: Design, build, and maintain features for our web interfaces (Patients, Clinicians, Labs, Administration).",
      "Scalable Architecture: Implement backend services that handle large volumes of biomedical data while ensuring performance, reliability, and security.",
      "HPC & Data Processing: Work on low-level programming, code optimization, (parallel computing), and cluster management to support advanced machine learning  workloads and large-scale analytics.",
      "Code Quality & Best Practices: Ensure maintainability, scalability, and robustness by following coding standards, performing code reviews, and embracing continuous integration/continuous delivery (CI/CD) practices.",
      "Multi-task in a fast-paced environment.",
    ],
    minimumRequirements: [
      "Education: Bachelor’s, Master’s, or a PhD. degree in Computer Science, Engineering, or a related field (or equivalent experience).",
      "Programming Languages: Proficiency in modern languages (e.g., Python, C++, JavaScript/TypeScript) and familiarity with frameworks such Net.js",
      "Systems & HPC: Experience with multi-threaded programming, distributed systems, and HPC architectures (MPI, CUDA, or similar).",
      "Databases & Data Management: Knowledge of databases and file systems for large-scale data storage and retrieval.",
    ],
    contactEmail: "contact@flahybase.com",
    emailSubject: "SEFHY",
  },
  {
    id: "sales relationship manager",
    jobTitle: "Sales Relationship Manager",
    about:
      "Flahy is a healthcare technology firm that develops artificial intelligence driven precision medicine products and collaborates with institutions for the development of targeted therapies. Flahy has developed FlahyBase, a digital biology platform that leverages sophisticated statistical & computational capabilities to analyze -omics and medical imaging data across individuals and populations for prognosis, personalizing treatment, and target discovery. FlahyRecovery™ is Flahy’s first precision medicine product built on the FlahyBase platform.",
    positionDescription:
      "We are seeking a Relationship Manager (Sales) to drive our growth and forge strong partnerships in the diagnostics space beginning oncology diagnostics. In this role, you will nurture existing relationships while aggressively acquiring new business. You will work closely with diagnostic laboratories marketing our flagship FlahyRecovery™ precision medicine product and work with medical providers who use FlahyRecovery™, and with whom we have Research & Development relations. Your goal is to increase FlahyRecovery™ usage, build strategic partnerships.",
    positionResponsibilities: [
      "Drive sales growth: Expand FlahyRecovery™ usage and develop new relationships to fuel revenue.",
      "Account management: Strengthen and manage existing accounts, ensuring seamless collaboration with diagnostic labs and healthcare providers.",
      "Partnership development: Work closely with medical providers to build long-term partnerships with the goal of improving health outcomes for their patients.",
      "Industry representation: Represent Flahy at networking events, and strategic sales meetings to increase brand visibility and generate leads.",
      "Cross-functional collaboration: Collaborate with internal teams (R&D) to tailor sales strategies to market needs and generate client feedback for product improvement.",
      "Strategic insights: Provide market insights and competitor analysis to inform business development strategies.",
    ],
    minimumRequirements: [
      "3-4 years of experience in oncology diagnostics sales or healthcare B2B sales.",
      "Proven ability to sell. Demonstrated track record of meeting and exceeding sales targets.",
      "Excellent communication, negotiation, and relationship-building skills.",
      "Understanding of precision medicine, medical imaging, and -omics data is a plus.",
      "Ability to navigate and build networks within the healthcare ecosystem.",
      "College Degree.",
    ],
    contactEmail: "contact@flahybase.com",
    emailSubject: "RMFHY",
  },
];

const JobPosting = () => {
  const { jobId } = useParams();
  const job = jobPostings.find((job) => job.id === jobId);

  if (!job) return <div>Job not found</div>;

  return (
    <>
      <Layout className="flex flex-col font-futura min-h-screen bg-white">
        <Header className="flex w-full p-0 bg-white">
          <NavigationBar />
        </Header>
        <Content className="bg-white  px-10 py-10 flex flex-col justify-center items-center">
          <div className="flex flex-col bg-[#F1F1EF] shadow-md shadow-[#dbdbd9] w-screen sm:w-screen md:w-10/12 lg:w-10/12 xl:w-10/12 2xl:w-10/12">
            <p className="font-proxima-nova text-3xl font-thin ml-6 mt-6">
              {job.jobTitle}
            </p>
            <p className="font-proxima-nova text-2xl font-thin mx-6 mt-6">
              About
            </p>
            <p className="font-proxima-nova text-base font-thin mt-2 mx-6">
              {job.about}
            </p>

            <p className="font-proxima-nova text-2xl font-thin mx-6 mt-6">
              Position Responsibilities
            </p>
            <ul className="list-disc ml-10">
              {job.positionResponsibilities.map((item, index) => (
                <li
                  key={index}
                  className=" font-proxima-nova text-base font-thin mt-2"
                >
                  {item}
                </li>
              ))}
            </ul>

            <p className="font-proxima-nova text-2xl font-thin mx-6 mt-6">
              Minimum Requirements
            </p>

            <ul className="list-disc ml-10">
              {job.minimumRequirements.map((item, index) => (
                <li
                  key={index}
                  className="font-proxima-nova text-base font-thin mt-2"
                >
                  {item}
                </li>
              ))}
            </ul>

            <p className="font-proxima-nova text-base font-thin mt-10 mb-6 mx-6">
              If interested, please email{" "}
              <a className="text-blue-500" href="mailto:contact@flahybase.com">
                contact@flahybase.com
              </a>{" "}
              with subject "{job.emailSubject}".
            </p>
          </div>
        </Content>
        <Footer className="p-0 bg-white">
          <AppFooter />
        </Footer>
      </Layout>
    </>
  );
};

export default JobPosting;
