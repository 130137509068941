import React from "react";
import NavigationBar from "./Components/mainNavigationBar";
import { Layout } from "antd";
import AppFooter from "./Components/Footer";
import HalvorsenAttractorComponent from "./Components/HalvorsenAttractor";
const { Header, Content, Footer } = Layout;

export function Base() {
  return (
    <>
      {/* background effect */}
      <Layout className="flex flex-col font-futura min-h-screen bg-[radial-gradient(ellipse_at_bottom,_var(--tw-gradient-stops))] from-[#05081c] via-[#05081c] to-[#090E32]">
        <Header className="flex w-full p-0 ">
          <NavigationBar />
        </Header>
        <Content className="z-20 mt-[-60px] h-screen justify-between">
          <div className="flex flex-wrap sm:flex-nowrap bg-[#070B27] h-screen relative overflow-hidden">
            <div className="flex-grow z-10 pt-5">
              <HalvorsenAttractorComponent />
            </div>
            <div className="absolute bg-transparent bottom-0 sm:top-0 sm:right-0 h-fit sm:h-full text-white flex justify-center items-center w-full sm:max-w-[30%]">
              <div className="text-sm sm:text-xl bg-gray-300/5 h-fit sm:h-full backdrop-blur-sm p-5 px-7 shadow-3xl top-0 right-0 justify-center items-center text-white flex flex-col z-10 border-2 border-[#ffffff05] self-center bg-white/10 shadow-3xlbg-white/10">
                <p className="mb-4">
                  Flahy is continuously refining and expanding FlahyBase, a digital biology platform that processes large-scale -omics and medical imaging data of individuals and populations in an interconnected manner, to generate insight that is relevant to precision medicine and target discovery.
                </p>
                <p>
                  Flahy is interested in collaborations that leverage FlahyBase. For further discussion, please email <a href="mailto:contact@flahybase.com" className="text-white-600 font-bold">contact@flahybase.com</a> with the subject “Collaboration”.
                </p>
              </div>
            </div>
          </div>
        </Content>
        <Footer className="p-0 z-10">
          <AppFooter />
        </Footer>
      </Layout>
    </>
  );
}
